// Define the base URL for your API
const BASE_URL = 'https://fcd-be-e3a65f61233d.herokuapp.com/'
// const BASE_URL = 'http://localhost:3111'

// API function for making GET requests
export const get = async (endpoint, token) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.json()
  } catch (error) {
    console.error('Error:', error.message)
    throw error
  }
}

// API function for making POST requests with Auth
export const post = async (endpoint, body, token) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
    return response.json()
  } catch (error) {
    console.error('Error:', error.message)
    throw error
  }
}
// API function for making POST requests without authToken
export const postNo = async (endpoint, body) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    return response.json()
  } catch (error) {
    console.error('Error:', error.message)
    throw error
  }
}

// API function for making PUT requests with Auth
export const put = async (endpoint, body, token) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  if (body !== null) {
    requestOptions.body = JSON.stringify(body)
  }
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, requestOptions)
    return response.json()
  } catch (error) {
    console.error('Error:', error.message)
    throw error
  }
}

// API function for making PUT requests without Auth
export const putNo = async (endpoint, body) => {
  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    return response.json()
  } catch (error) {
    console.error('Error:', error.message)
    throw error
  }
}
